import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

function MyFooter() {
  return (
    <Box
      py={1}
      px={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "#1976d2",
      }}
    >
      <Typography color={"white"} variant="body">
        Thanks for using our service! Contact us at&nbsp;
        <a href="mailto:hello@mycostcosavings.com">hello@mycostcosavings.com</a>
        , MyCostcoSavings is not affiliated in any way with Costco.
      </Typography>
    </Box>
  );
}

export default MyFooter;
