import MyHeader from "./MyHeader";
import MyFooter from "./MyFooter";
import Intro from "./Intro";
import MyNav from "./MyNav";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MyForm from "./MyForm";
import { Box } from "@mui/system";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import MyScanner2 from "./MyScanner2";
import IntroductionWarehouse from "./IntroWarehouse";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOWXVhmtbKq6XUNLafQoaztw6m_hLvJ1s",
  authDomain: "costcosavings.firebaseapp.com",
  projectId: "costcosavings",
  storageBucket: "costcosavings.appspot.com",
  messagingSenderId: "40762083281",
  appId: "1:40762083281:web:bf3bfe5d613f4ac9cd82f0",
  measurementId: "G-9Z0P2QEP3N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
getAnalytics(app);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <CssBaseline />
        <MyNav></MyNav>
        <MyHeader />
        <Intro></Intro>
        <IntroductionWarehouse></IntroductionWarehouse>
        <MyScanner2></MyScanner2>
        <MyForm />
        <MyFooter />
      </Box>
    </LocalizationProvider>
  );
}

export default App;
