import { Box, Stack, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export default function IntroductionWarehouse() {
  return (
    <Box>
      <Stack
        alignItems="center"
        bgcolor="lightsteelblue"
        spacing={2}
        pt={6}
        pb={6}
      >
        <Typography variant="h4" color="black">
          How it works for Costco warehouse?
        </Typography>
        <Stepper orientation="vertical" activeStep={-1}>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Shop at your local Costco Warehouse as usual
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Enter your contact info and scan your receipt
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Sit back and let we monitor prices
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Receive an email if the price drops
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Stack>
    </Box>
  );
}
