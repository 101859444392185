import { Box, Paper, Stack, Typography } from "@mui/material";

export default function Intro() {
  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        spacing={2}
        px={1}
        py={6}
        bgcolor="#1976d2"
      >
        <Paper elevation={5} sx={{ p: 4 }}>
          <Typography variant="h5">Shop Confidently</Typography>
          <Typography variant="body1">Never miss out on discounts</Typography>
        </Paper>
        <Paper elevation={5} sx={{ p: 4 }}>
          <Typography variant="h5">Effortless Savings</Typography>
          <Typography variant="body1">No more manual price tracking</Typography>
        </Paper>
        <Paper elevation={5} sx={{ p: 4 }}>
          <Typography variant="h5">Instant Notification</Typography>
          <Typography variant="body1">Receive email for price drop</Typography>
        </Paper>
      </Stack>
    </Box>
  );
}
