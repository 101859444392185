import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";
import { Box, InputAdornment, Snackbar, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { MuiForm, MuiSelect, MuiTextField } from "react-mui-form-validator";
import MyInstruction from "./MyInstruction";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDOWXVhmtbKq6XUNLafQoaztw6m_hLvJ1s",
  authDomain: "costcosavings.firebaseapp.com",
  projectId: "costcosavings",
  storageBucket: "costcosavings.appspot.com",
  messagingSenderId: "40762083281",
  appId: "1:40762083281:web:bf3bfe5d613f4ac9cd82f0",
  measurementId: "G-9Z0P2QEP3N",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const BASE_URL = "https://costco-savings-afd48a55ac85.herokuapp.com";
const API_URL = `${BASE_URL}/api/v1/tickets/create`;

// const BASE_URL = "http://localhost"
// const API_URL = `${BASE_URL}:5001/api/v1/tickets/create`;

const SUCCESS_MSG = "Congrats! You have successfully created a ticket!";
const ERROR_MSG =
  "Something is wrong! Please check your inputs and try again later.";

export default function MyForm() {
  const [email, setEmail] = useState("");
  const [itemList, setItemList] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const changeEmail = (event) => {
    setEmail(event.target.value);
    logEvent(analytics, "changing_email");
  };

  const handleChange = (event, index) => {
    logEvent(analytics, "changing_items");
    const newItemList = itemList.map((item, i) => {
      if (index === i) {
        return { ...item, [event.target.name]: event.target.value };
      } else {
        return item;
      }
    });
    setItemList(newItemList);
  };

  const handleSubmit = () => {
    setLoading(true);
    logEvent(analytics, "ticket_submission");

    const option = {
      headers: { "X-API-Key": "jingshan" },
    };
    axios
      .post(
        `${API_URL}`,
        {
          email: email,
          items: itemList,
        },
        option,
      )
      .then(function (response) {
        logEvent(analytics, "ticket_submission_success");
        setItemList([]);
        setEmail("");
        setMessage(SUCCESS_MSG);
        setOpen(true);
        setLoading(false);
      })
      .catch(function (error) {
        logEvent(analytics, "ticket_submission_error");
        console.log(error);
        setMessage(ERROR_MSG);
        setOpen(true);
        setLoading(false);
      });
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleDelete = (index) => {
    logEvent(analytics, "delete_item");
    const newItemList = itemList.filter((_, i) => i !== index);
    setItemList(newItemList);
  };

  const handleAdd = () => {
    logEvent(analytics, "add_item");
    const newItemList = [
      { itemNumber: "", paidPrice: "", quantity: 1 },
      ...itemList,
    ];
    setItemList(newItemList);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box py={1} sx={{ bgcolor: "white" }}>
      <Stack
        alignItems="center"
        bgcolor="lightsteelblue"
        spacing={2}
        pt={6}
        pb={6}
      >
        <Typography variant="h4" color="black">
          How it works for Costco.com?
        </Typography>
        <Stepper orientation="vertical" activeStep={-1}>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Shop at{" "}
                <a
                  href="https://www.costco.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Costco.com
                </a>{" "}
                as usual
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Enter your email and item information below
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Sit back and let we monitor prices
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography color="black" variant="h6">
                Receive an email if the price drops
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Stack>
      <MuiForm
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <Stack
          mt={2}
          spacing={2}
          sx={{ mx: { xs: 4, sm: 10, md: 20, lg: 40, xl: 80 } }}
        >
          <Typography variant="h6" color={"black"}>
            Create ticket below to get notified when you can save money within
            30 days
          </Typography>
          <Typography variant="h6" color={"black"}>
            (OR forward costco order confirmation email to us. More
            details&nbsp;
            <Link href="#instruction" id="instruction">
              below
            </Link>
            )
          </Typography>
          <MuiTextField
            name="email"
            required
            label="Email (Required)"
            placeholder="Email"
            onChange={changeEmail}
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            fullWidth
          />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Add Item
          </Button>

          {itemList.map((item, index) => (
            <Paper key={index}>
              <Stack
                justifyContent="center"
                direction="column"
                p={3}
                spacing={2}
              >
                <MuiTextField
                  name="itemNumber"
                  required
                  label="Item No. (Required)"
                  placeholder="Item Number"
                  onChange={(event) => handleChange(event, index)}
                  value={item.itemNumber}
                  validators={["required", "matchRegexp:^[0-9]*$"]}
                  errorMessages={[
                    "this field is required",
                    "this field should be the item number",
                  ]}
                  fullWidth
                />
                <MuiTextField
                  name="paidPrice"
                  required
                  label="Price before tax (Required) "
                  placeholder="Paid Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={(event) => handleChange(event, index)}
                  value={item.paidPrice}
                  validators={["required", "matchRegexp:^[0-9]+([.]?[0-9]+)?$"]}
                  errorMessages={[
                    "this field is required",
                    "this field should be valid number",
                  ]}
                  fullWidth
                />
                <MuiSelect
                  name="quantity"
                  label="Quantity (Required)"
                  required
                  placeholder="Quantity"
                  onChange={(event) => handleChange(event, index)}
                  value={item.quantity}
                  validators={["minNumber:1", "maxNumber:10"]}
                  errorMessages={[
                    "quantity cannot be less than 1",
                    "quantity cannot be greater then 10",
                  ]}
                  fullWidth
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </MuiSelect>
                <Tooltip title="Delete Item">
                  <IconButton
                    aria-label="delete"
                    color="info"
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Paper>
          ))}

          {itemList.length > 0 && (
            <Stack
              my={2}
              spacing={2}
              sx={{ mx: { xs: 4, sm: 10, md: 20, lg: 40, xl: 80 } }}
            >
              <Typography color="grey" variant="body1">
                Please make sure the item number(s) and price(s) are correct
                before creating ticket
              </Typography>
              <LoadingButton
                type="submit"
                loading={loading}
                disabled={itemList.length === 0}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                size="large"
                variant="outlined"
              >
                Create Ticket
              </LoadingButton>
            </Stack>
          )}
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={message}
            action={action}
          ></Snackbar>
        </Stack>
      </MuiForm>
      <MyInstruction id="instruction"></MyInstruction>
    </Box>
  );
}
