import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import PeopleIcon from "@mui/icons-material/People";
import SavingsIcon from "@mui/icons-material/Savings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LaptopIcon from "@mui/icons-material/Laptop";

function MyHeader() {
  return (
    <Stack
      alignItems="center"
      spacing={2}
      sx={{
        bgcolor: "#1976d2",
        paddingBottom: 2,
        pt: 2,
      }}
    >
      <Typography color={"white"} variant="h4" sx={{ pt: 2, px: 2 }}>
        Save More Effortlessly
      </Typography>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <ShoppingCartIcon
            sx={{ color: "white" }}
            fontSize="large"
          ></ShoppingCartIcon>
          <Typography color={"white"} variant="h6" sx={{ px: 2 }} maxWidth="md">
            Do you know you can get more savings if the item you purchased on
            Costco Warehouse or Costco.com has a price drop within 30 days?
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <LaptopIcon sx={{ color: "white" }} fontSize="large"></LaptopIcon>
          <Typography color={"white"} variant="h6" sx={{ px: 2 }} maxWidth="md">
            MyCostcoSavings will help you track the item price so you won't
            leave any savings on the table any more
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <PeopleIcon sx={{ color: "white" }} fontSize="large"></PeopleIcon>
          <Typography color={"white"} variant="h6" sx={{ px: 2 }} maxWidth="md">
            10k+ people monthly saving more with MyCostcoSavings when shopping
            with Costco
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <SavingsIcon sx={{ color: "white" }} fontSize="large"></SavingsIcon>
          <Typography color={"white"} variant="h6" sx={{ px: 2 }} maxWidth="md">
            On average $43.50 saving for each ticket on MyCostcoSavings with
            order value over $500
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default MyHeader;
