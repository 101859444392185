import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Tooltip from "@mui/material/Tooltip";
import Resizer from "react-image-file-resizer";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, InputAdornment, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { MuiForm, MuiTextField } from "react-mui-form-validator";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1920,
      "JPEG",
      10,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
    );
  });

function getNumber(str1) {
  if (/^[0-9]+$/.test(str1)) {
    return str1;
  } else if (/^[0-9]+.*[0-9]$/.test(str1)) {
    return str1;
  } else {
    return "";
  }
}

function getPrice(str1) {
  let negative = false;
  if (str1.endsWith("-")) {
    str1 = str1.replace("-", "");
    negative = true;
  }
  if (/^[1-9]{1}[0-9]*[.]{1}[0-9]{2}$/.test(str1)) {
    return negative ? "-" + str1 : str1;
  } else {
    return "";
  }
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const firebaseConfig = {
  apiKey: "AIzaSyDOWXVhmtbKq6XUNLafQoaztw6m_hLvJ1s",
  authDomain: "costcosavings.firebaseapp.com",
  projectId: "costcosavings",
  storageBucket: "costcosavings.appspot.com",
  messagingSenderId: "40762083281",
  appId: "1:40762083281:web:bf3bfe5d613f4ac9cd82f0",
  measurementId: "G-9Z0P2QEP3N",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const BASE_URL = "https://costco-savings-afd48a55ac85.herokuapp.com";
const API_URL = `${BASE_URL}/api/v1/warehouse/tickets/create`;

// const BASE_URL = "http://localhost";
// const API_URL = `${BASE_URL}:5001/api/v1/warehouse/tickets/create`;

const SUCCESS_MSG = "Congrats! You have successfully created a ticket!";
const ERROR_MSG =
  "Something is wrong! Please check your inputs and try again later.";

export default function MyScanner2() {
  const [email, setEmail] = useState("");
  const [warehouseNumber, setWarehouseNumber] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [missingItems, setMissingItems] = useState(0);
  const [itemsSold, setItemsSold] = useState(0);
  const [itemList, setItemList] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [unrecognizedReceipt, setUnRecognizedReceipt] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeEmail = (event) => {
    logEvent(analytics, "warehouse_changing_email");
    setEmail(event.target.value);
  };

  const changeOrderDate = (event) => {
    logEvent(analytics, "warehouse_changing_order_date");
    setOrderDate(event.target.value);
  };

  const changeWarehouseNumber = (event) => {
    logEvent(analytics, "warehouse_changing_warehouse_number");
    setWarehouseNumber(event.target.value);
  };

  const handleChange = (event, index) => {
    logEvent(analytics, "warehouse_changing_items");
    const newItemList = itemList.map((item, i) => {
      if (index === i) {
        return { ...item, [event.target.name]: event.target.value };
      } else {
        return item;
      }
    });
    setItemList(newItemList);
  };

  const handleDelete = (index) => {
    logEvent(analytics, "warehouse_delete_item");
    const newItemList = itemList.filter((_, i) => i !== index);
    setItemList(newItemList);
    let missingItemsTmp = 0;
    if (itemsSold > newItemList.length) {
      missingItemsTmp = itemsSold - newItemList.length;
    }
    setMissingItems(missingItemsTmp);
  };

  const handleAdd = () => {
    logEvent(analytics, "warehouse_add_item");
    const newItemList = [...itemList, { itemNumber: "", paidPrice: "" }];
    setItemList(newItemList);
    let missingItemsTmp = 0;
    if (itemsSold > newItemList.length) {
      missingItemsTmp = itemsSold - newItemList.length;
    }
    setMissingItems(missingItemsTmp);
  };

  const handleUploadClick = async (event) => {
    logEvent(analytics, "warehouse_upload_click");
    setUploadLoading(true);
    if (event.target.files.length === 0) {
      logEvent(analytics, "warehouse_cancel_upload_click");
      setUploadLoading(false);
      return;
    }
    setUploadError(false);
    setUnRecognizedReceipt(false);
    setMissingItems(0);
    setItemsSold(0);
    setWarehouseNumber("");
    setOrderDate("");
    setItemList([]);
    const file2 = event.target.files[0];
    const file = await resizeFile(file2);

    event.target.value = "";
    const option = {
      headers: { apikey: "K85682999188957" },
    };
    const FormData = require("form-data");
    const form = new FormData();
    form.append("file", file, file.name);

    // const apiUrl = "https://ocr.asprise.com/api/v1/receipt";
    // form.append("api_key", "TEST");
    // form.append("recognizer", "auto");

    const apiUrl = "https://api.ocr.space/Parse/Image";
    form.append("OCREngine", 2);
    form.append("isTable", true);
    form.append("scale", true);
    // form.append("detectOrientation", true);

    axios
      .post(apiUrl, form, option)
      // .post(apiUrl, form)
      .then(function (response) {
        // console.log(response);
        // const ocr_text = response.data.receipts[0].ocr_text;
        // const lines = ocr_text.split("\n");

        try {
          const parsedText = response.data.ParsedResults[0].ParsedText;
          // console.log(parsedText);
          const lines = parsedText.split("\t\r\n");

          let itemListTmp = [];
          let orderDateTmp = "";
          let warehouseNumberTmp = "";
          let itemsSoldTmp = 0;
          let costcoReceipt = false;
          if (lines.length > 1 && lines[0].toLowerCase().includes("costco")) {
            costcoReceipt = true;
          }
          if (!costcoReceipt) {
            setUnRecognizedReceipt(true);
            setUploadLoading(false);
            logEvent(analytics, "warehouse_upload_unrecognized_receipt");
            return;
          }
          for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (
              line.toLowerCase().includes("wholesale") &&
              warehouseNumberTmp === ""
            ) {
              // get the warehouse number
              const whHouseParts = lines[i + 1].split("#");
              const whHouse = whHouseParts[1].trim();
              // console.log("getting warehousenumber: " + whHouse);
              warehouseNumberTmp = whHouse;
              i = i + 1;
            } else if (
              line.toLowerCase().startsWith("amount") &&
              orderDateTmp === ""
            ) {
              // get the purchase date
              const pDate = lines[i + 1].split(" ")[0].trim();
              // console.log("get purchaseDate: " + pDate);
              orderDateTmp = pDate;
              i = i + 1;
            } else if (line.startsWith("TOTAL") && line.includes("=")) {
              // get the items sold
              itemsSoldTmp = parseInt(line.split("=")[1].trim());
            } else if (line.includes("Sold: ")) {
              // get the items sold
              itemsSoldTmp = parseInt(line.split("Sold: ")[1].trim());
            } else {
              // get itemNumber and price
              if (line.endsWith("A")) {
                line = line.substring(0, line.length - 1);
              }
              line = line.replace("E", "").replace("F", "").trim();

              const itemParts = line.split(/\s/);
              const itemNum = getNumber(itemParts[0]);
              if (itemNum !== "") {
                const price = getPrice(itemParts[itemParts.length - 1].trim());
                if (price !== "") {
                  // console.log(itemNum + " " + price);
                  if (price.startsWith("-")) {
                    let lastItem = itemListTmp.pop();
                    let p = Number(lastItem.paidPrice) + Number(price);
                    lastItem.paidPrice = p.toFixed(2) + "";

                    itemListTmp.push(lastItem);
                  } else {
                    itemListTmp.push({ itemNumber: itemNum, paidPrice: price });
                  }
                }
              }
            }
          }
          if (orderDateTmp === "") {
            // get the purchase date again
            const pDate = lines[lines.length - 2].split(" ")[1].trim();
            // console.log("getting pDate with extra step: " + pDate);
            orderDateTmp = pDate;
          }
          let missingItemsTmp = 0;
          if (itemsSoldTmp > itemListTmp.length) {
            missingItemsTmp = itemsSoldTmp - itemListTmp.length;
          }
          setMissingItems(missingItemsTmp);
          setItemsSold(itemsSoldTmp);
          setWarehouseNumber(warehouseNumberTmp);
          setOrderDate(orderDateTmp);
          setItemList(itemListTmp);
          setUploadLoading(false);
          if (itemListTmp.length === 0) {
            setUploadError(true);
          }
          setUploadSuccess(true);
          logEvent(analytics, "warehouse_upload_successfully");
        } catch (error) {
          logEvent(analytics, "warehouse_upload_parse_error");
          setUploadError(true);
          console.log(error);
        }
      })
      .catch(function (error) {
        logEvent(analytics, "warehouse_ocr_api_response_error");
        console.log(error);
        setUploadLoading(false);
        setUploadError(true);
      });
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    logEvent(analytics, "warehouse_ticket_submission");
    setLoading(true);

    const option = {
      headers: { "X-API-Key": "jingshan" },
    };
    axios
      .post(
        `${API_URL}`,
        {
          email: email,
          phone: "",
          warehouseNumber: warehouseNumber,
          orderDate: orderDate,
          items: itemList,
        },
        option,
      )
      .then(function (response) {
        logEvent(analytics, "warehouse_ticket_submission_success");
        setMessage(SUCCESS_MSG);
        setOpen(true);
        setEmail("");
        setWarehouseNumber("");
        setMissingItems(0);
        setItemsSold(0);
        setItemList([]);
        setLoading(false);
      })
      .catch(function (error) {
        logEvent(analytics, "warehouse_ticket_submission_error");
        console.log(error);
        setMessage(ERROR_MSG);
        setOpen(true);
        setLoading(false);
      });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box py={1}>
      <MuiForm
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <Stack
          my={2}
          spacing={2}
          sx={{ mx: { xs: 4, sm: 10, md: 20, lg: 40, xl: 80 } }}
        >
          <Stack justifyContent="space-between" spacing={1}>
            <MuiTextField
              name="email"
              required
              label="Email (Required)"
              placeholder="Email"
              onChange={changeEmail}
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "email is not valid"]}
              fullWidth
            />
          </Stack>
          <LoadingButton
            component="label"
            loading={uploadLoading}
            loadingPosition="start"
            onChange={handleUploadClick}
            startIcon={<CloudUploadIcon />}
            size="large"
            variant="outlined"
          >
            Scan Receipt
            <VisuallyHiddenInput type="file" />
          </LoadingButton>
          {uploadError && (
            <Typography variant="h6">
              Error uploading receipt. Please scan again later OR enter the
              receipt info manually below
            </Typography>
          )}
          {unrecognizedReceipt && (
            <Typography variant="h6">
              Please scan again with a Costco receipt OR enter the receipt info
              manually below
            </Typography>
          )}
        </Stack>

        {(uploadSuccess || uploadError || unrecognizedReceipt) && (
          <Box>
            <Stack
              mt={2}
              spacing={2}
              sx={{ mx: { xs: 4, sm: 10, md: 20, lg: 40, xl: 80 } }}
            >
              <Stack direction="row" spacing={5}>
                <MuiTextField
                  name="warehouseNumber"
                  required
                  label="Warehouse No. (Required)"
                  placeholder="Warehouse Number"
                  onChange={changeWarehouseNumber}
                  value={warehouseNumber}
                  validators={["required", "matchRegexp:^[0-9]*$"]}
                  errorMessages={[
                    "this field is required",
                    "this field should be the warehouse number",
                  ]}
                  fullWidth
                />
                <MuiTextField
                  name="orderDate"
                  label="Order Date"
                  placeholder="MM/DD/YYYY"
                  onChange={changeOrderDate}
                  value={orderDate}
                  validators={[
                    "required",
                    "matchRegexp:^(0[1-9]|1[012])/(0[1-9]|[12][0-9]|3[01])/20\\d\\d$",
                  ]}
                  errorMessages={[
                    "this field is required",
                    "this field should be the order date in the formot of MM/DD/YYYY",
                  ]}
                  fullWidth
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Typography variant="h6">
                  Items Info({missingItems} missing items)
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                >
                  Add Item
                </Button>
              </Stack>

              {itemList.map((item, index) => (
                <Paper key={index}>
                  <Stack
                    justifyContent="center"
                    direction={{ xs: "column", sm: "row" }}
                    m={2}
                    spacing={2}
                  >
                    <MuiTextField
                      name="number"
                      disabled
                      label="No."
                      value={index + 1}
                      fullWidth
                    />
                    <MuiTextField
                      name="itemNumber"
                      required
                      label="Item No. (Required)"
                      placeholder="Item Number"
                      onChange={(event) => handleChange(event, index)}
                      value={item.itemNumber}
                      validators={["required", "matchRegexp:^[0-9]*$"]}
                      errorMessages={[
                        "this field is required",
                        "this field should be the item number",
                      ]}
                      fullWidth
                    />
                    <MuiTextField
                      name="paidPrice"
                      label="Price before tax (Required) "
                      required
                      placeholder="Paid Price"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onChange={(event) => handleChange(event, index)}
                      value={item.paidPrice}
                      validators={[
                        "required",
                        "matchRegexp:^[0-9]+([.]?[0-9]+)?$",
                      ]}
                      errorMessages={[
                        "this field is required",
                        "this field should be valid number",
                      ]}
                      fullWidth
                    />
                    <Tooltip title="Delete Item">
                      <IconButton
                        aria-label="delete"
                        color="info"
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Paper>
              ))}
            </Stack>

            <Stack
              my={2}
              spacing={2}
              sx={{ mx: { xs: 4, sm: 10, md: 20, lg: 40, xl: 80 } }}
            >
              <Typography color="grey" variant="body1">
                Please make sure the warehouse number, order date, item number(s) and price(s) are correct
                before creating ticket
              </Typography>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                size="large"
                disabled={warehouseNumber === "" || itemList.length === 0}
                variant="outlined"
              >
                Create Ticket
              </LoadingButton>
            </Stack>
          </Box>
        )}
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={message}
          action={action}
        ></Snackbar>
      </MuiForm>
    </Box>
  );
}
