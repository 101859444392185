import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import { List, ListItem, Stack } from "@mui/material";
import { Box } from "@mui/system";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import order_confirmation_email from "./images/order_confirmation_email.png";

export default function MyInstruction() {
  return (
    <Stack
      mt={4}
      spacing={2}
      sx={{ mx: { xs: 4, sm: 10, md: 20, lg: 40, xl: 80 } }}
    >
      <Typography variant="h6" mb={2}>
        Frequently Asked Questions
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="my-instruction-1">
          <Stack direction="row" spacing={1}>
            <HelpIcon></HelpIcon>
            <Typography sx={{ display: "flex", justifyContent: "center" }}>
              How to get the Item Number, Paid Price, Quantity, etc..?
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <List>
              <ListItem>
                1. Login&nbsp;
                <a
                  href="https://www.costco.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Costco.com
                </a>
                &nbsp;account.
              </ListItem>
              <ListItem>2. Click &#34;Account&#34;.</ListItem>
              <ListItem>3. Click &#34;Orders and Purchases&#34;.</ListItem>
              <ListItem>4. Click &#34;View Order Details&#34;</ListItem>
              <ListItem>
                5. You will see &#34;Item Number&#34;, &#34;Paid Price&#34;,
                &#34;Quantity&#34;
              </ListItem>
              <ListItem>
                6. You can also find the &#34;Order Number&#34;, &#34;Order
                Date&#34;.
              </ListItem>
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="my-instruction-2">
          <Stack direction="row" spacing={1}>
            <HelpIcon></HelpIcon>
            <Typography sx={{ display: "flex", justifyContent: "center" }}>
              How to find the Costco order confirmation email for the item?
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <List>
              <ListItem>
                1. Search your email from orderstatus@costco.com
              </ListItem>
              <ListItem>2. Our email: hello@mycostcosavings.com</ListItem>
              <ListItem>
                3. Here is an example for the confirmation email
              </ListItem>
              <ImageList cols={1}>
                <ImageListItem>
                  <img
                    src={order_confirmation_email}
                    alt="Costco order confirmation email"
                    loading="lazy"
                  />
                </ImageListItem>
              </ImageList>
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
